

/* ************************************************** */



.inner-title {
    font-family: "inter-Bold";
    font-size: 25px;
    color: #444;
    margin-bottom: 20px;
  }
  
.ant-layout-content {
    width: 100%;
    height: 100%;
    overflow: hidden !important;
    position: relative;
}
.content {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: -17px;
    padding: 20px;
    overflow-y: scroll;
}
.ant-breadcrumb {
    margin-bottom: 25px;
}
.innerContent {
    font-family: "inter-regular";
    font-size: 13px;
    color: #000;
    background-color: #fff;
    padding: 30px;
    border: 1px solid #ebebeb;
}
.content-left {
    margin-right: 18% !important;
}
.ant-form-item-label > label.ant-form-item-required::before {
    display: none !important;
}
.ant-form-vertical .ant-form-item-label {
    margin-bottom: 0px;
}
label.ant-form-item-required {
    color: #000 !important;
    font-family: "inter-regular" !important;
    font-size: 14px !important;
}
.ant-form-large .ant-form-item-label > label {
    height: auto;
}
.form-row {
    height: 40px;
    margin-top: 15px;
}
.primary-btn {
    font-family: "inter-medium";
    text-transform: uppercase;
    font-size: 13px !important;
    border-radius: 8px !important;
    padding: 10px 20px !important;
    width: 100%;
    color: #9a9a9a;
    border: 1px solid #9a9a9a 9a;
}
.Form-btn-container {
    display: flex;
    margin-top: 30px;
    align-items: flex-end;
}
.ant-btn:hover, .ant-btn:focus {
    color: #9a9a9a;
    background-color: #fff;
    border-color: #9a9a9a;
}
.ant-input {
    font-family: "inter-regular" !important;
    color: #333 !important;
    padding: 8px 10px !important;
    height: 40px !important;
}

.ant-calendar-picker{
    width: 100% !important;
}
.center-start {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.colSplit {
    width: 50%;
    padding-left: 40px;
    padding-right: 40px;
}
.footer {
    background-color: #fcfcfe;
    padding: 30px;
}
.backBtn {
    font-family: "inter-Bold";
    color: #e84c89 !important;
    border: 1px solid #e84c89 !important;
    /* color: #493db8 !important; */
    background-color: #fff !important;
    /* border: 1px solid #493db8 !important; */
    width: 60%;
    border-radius: 5px !important;
    padding: 10px 0 10px 0 !important;
    height: 100% !important;
    margin: 10px 0px 0px !important;
    box-shadow: 5px 4px 0px 0px #edcede !important;
    /* box-shadow: 5px 4px 0px 0px #d1ceed !important; */
}

.completeDiv {
    margin-top: 20px;
    font-family: "inter-Bold";
    font-size: 15px;
    text-align: center;
}
.nextBtn {
    font-family: "inter-Bold";
    /* background-color: ##e84c89 !important ; */
    /* background-color: #493db8 !important ; */
    color: #fff !important;
    border: 0px !important;
    width: 60%;
    border-radius: 5px !important;
    padding: 10px 0 10px 0 !important;
    height: 100% !important;
    margin: 10px 0px 0px !important;
    box-shadow: 5px 4px 0px 0px #edcede !important;
    /* box-shadow: 5px 4px 0px 0px #d1ceed !important; */
}

.disabledBtn{
    /* background-color: #493db875 !important ; */
    font-family: "inter-Bold";
    color: #fff !important;
    border: 0px !important;
    width: 60%;
    border-radius: 5px !important;
    padding: 10px 0 10px 0 !important;
    height: 100% !important;
    margin: 10px 0px 0px !important;
    box-shadow: 5px 4px 0px 0px #d1ceed !important;
}
.inputplace {
    font-family: "Inter-Regular";
    font-size: 13px;
    margin-top: 5px !important;
    padding: 8px 10px !important;
    color: #7d7d7d !important;
    border-color: #bfbfbf !important;
}

.ant-select-selection--multiple .ant-select-selection__rendered {
    /* margin-top: 5px !important;  */
    height: auto !important;
    min-height: 40px;

}
div#CPT_codes {
    height: auto !important;
}