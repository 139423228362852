


 .ant-layout {
    display: flex;
    -ms-flex: auto;
    flex: auto;
    -ms-flex-direction: column;
    flex-direction: column;
    min-height: 0;
    background-color: #f9f9f9;
    bottom: 0px !important;
    padding: 0px;
    margin: 0px;
    height: 100% !important;
    height: -webkit-fill-available !important;
}
.ant-layout-header {
    height: 70px;
    margin-top: 0px !important;
    padding: 0px 0px 0px 25px !important;
    color: rgba(0, 0, 0, 0.85);
    line-height: 60px;
    background: #fff;
}
.logo {
    width: 80px !important;
    margin-right: 10px;
}
.ant-input-prefix {
    margin-right: 8px;
    color: #979797;
}
.ant-input {
    font-family: "inter-medium";
    color: #333 !important;
    padding: 5px 10px !important;
}
.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    color: #bdbdbd;
}
.ant-layout-sider {
    position: relative;
    min-width: 250px !important;
    width: 350px !important;
    background: #979797;
   /* max-width: 350px !important;
    */
}
.Avatar-Icon {
    display: flex;
    justify-content: space-between;
    padding: 0px 15px 0px;
    align-items: center;
    font-size: 20px;
    color: #ececec;
    border-left: 1px solid #dedede;
    height: 70px;
}
.ant-avatar {
    vertical-align: middle;
    background: #dedede;
    width: 30px !important;
    height: 30px !important;
    line-height: 38px !important;
    border-radius: 50%;
}
.fa-user {
    color: #acacac;
    font-size: 20px;
}
.icon-gray {
    color: #979797;
    font-size: 22px !important;
}
.icon-right {
    right: 0px;
}
