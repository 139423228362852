 .ant-input {
    height: 40px !important;
    /* border-radius: 12px;
    border: solid 1px #e6e6e6; */
    background-color: #ffffff;
}
 .ant-select {
    height: 40px !important;
    /* min-height: 40px !important; */
    /* border-radius: 12px;
    border: solid 1px #e6e6e6; */
    background-color: #ffffff;
}

.ant-select-selection {
    height: 100%;
    border-radius: 7px;
    border: solid 1.5px #bab9b9;
    background-color: #ffffff;
}

.ant-select-selection__rendered{
     height: 100%;
}
.ant-form-item-label {
    display: inline-block;
    overflow: hidden;
    font-family: "inter-bold";
    font-size: 10px;
    font-weight: bold;
    /* line-height: 45.9999px !important;   default height = 39.9999px */
    white-space: nowrap;
    text-align: left;
    vertical-align: middle;
}
.ant-form-item-label > label{
    color: #000000a6 !important;
}
.ant-select-selection-selected-value {
   height: 100% !important;
   padding: 5px 0px;
   font-family: "inter-medium";
  }
  
  .ant-select-selection.ant-select-selection--single {
    height: 100% !important;
  }
.ant-select-selection__placeholder, .ant-select-search__field__placeholder{
    font-family: "inter-medium";

}
/* 
.container{
    display: inline-block;
    width: 100%;
    height: 100%;

}

.card-Container{

    display: block;
    padding: 25px;
    box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.3);
    border-radius: 15PX;
}

.card-container-buttons-container{ 
    display: flex;
    justify-content: space-between;    
}

.card-container-primaryButton{
    width: 100%;

} */


/* ************************************************** */

.root{

        display: flex;
        position: absolute;
        width: 100%;
        height: 100%;

}


.inner-title {
    font-family: "inter-Bold";
    font-size: 25px;
    color: #444;
    margin-bottom: 20px;
  }
  
.ant-upload-list-item-info {
    height: 100%;
    text-align: left;
    padding: 0 12px 0 4px;
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
}
.ant-layout-content {
    width: 100%;
    height: 100%;
    overflow: hidden !important;
    position: relative;
}
.content {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: -17px;
    padding: 20px;
    overflow-y: scroll;
}
.ant-breadcrumb {
    margin-bottom: 25px;
}
.innerContent {
    font-family: "inter-regular";
    font-size: 13px;
    color: #000;
    background-color: #fff;
    padding: 30px;
    border: 1px solid #ebebeb;
}
.content-left {
    margin-right: 18% !important;
}
.ant-form-item-label > label.ant-form-item-required::before {
    display: none !important;
}
.ant-form-vertical .ant-form-item-label {
    margin-bottom: 0px;
}
label.ant-form-item-required {
    color: #000 !important;
    font-family: "inter-regular" !important;
    font-size: 14px !important;
}
.ant-form-large .ant-form-item-label > label {
    height: auto;
}
.form-row {
    height: 40px;
    margin-top: 15px;
}
.primary-btn {
    font-family: "inter-medium";
    text-transform: uppercase;
    font-size: 13px !important;
    border-radius: 8px !important;
    padding: 10px 20px !important;
    width: 100%;
    color: #9a9a9a;
    border: 1px solid #9a9a9a 9a;
}
.Form-btn-container {
    display: flex;
    margin-top: 30px;
    align-items: flex-end;
}
.ant-btn:hover, .ant-btn:focus {
    color: #9a9a9a;
    background-color: #fff;
    border-color: #9a9a9a;
}
.ant-input {
    font-family: "inter-regular" !important;
    color: #333 !important;
    padding: 8px 10px !important;
}
.center-start {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.colSplit {
    width: 50%;
    padding-left: 40px;
    padding-right: 40px;
}
.footer {
    background-color: #fcfcfe;
    padding: 30px;
}
.backBtn {
    font-family: "inter-Bold";
    color: #e84c89 !important;
    border: 1px solid #e84c89 !important;
    /* color: #493db8 !important; */
    background-color: #fff !important;
    /* border: 1px solid #493db8 !important; */
    width: 60%;
    border-radius: 5px !important;
    padding: 10px 0 10px 0 !important;
    height: 100% !important;
    margin: 10px 0px 0px !important;
    box-shadow: 5px 4px 0px 0px #edcede !important;
    /* box-shadow: 5px 4px 0px 0px #d1ceed !important; */
}
.completeDiv {
    margin-top: 20px;
    font-family: "inter-Bold";
    font-size: 15px;
    text-align: center;
}
.nextBtn {
    font-family: "inter-Bold";
    /* background-color: ##e84c89 !important; */
    /* background-color: #493db8 !important; */
    color: #fff !important;
    border: 0px !important;
    width: 60%;
    border-radius: 5px !important;
    padding: 10px 0 10px 0 !important;
    height: 100% !important;
    margin: 10px 0px 0px !important;
    box-shadow: 5px 4px 0px 0px #edcede !important;
    /* box-shadow: 5px 4px 0px 0px #d1ceed !important; */
}
.inputplace {
    font-family: "Inter-Regular";
    font-size: 13px;
    margin-top: 5px !important;
    padding: 8px 10px !important;
    color: #7d7d7d !important;
    border-color: #bfbfbf !important;
}
