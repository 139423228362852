
@font-face {
  font-family: "inter-medium";
  font-size: normal;
  font-weight: 400;
  src: url(./assets/fonts/Inter-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Inter-Regular";
  font-size: normal;
  font-weight: 400;
  src: url(./assets/fonts/Inter-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "inter-Bold";
  /* font-size: ; */
  font-weight: 700;
  src: url(./assets/fonts/Inter-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "inter-ExtraBold";
  /* font-size: ; */
  font-weight: 700;
  src: url(./assets/fonts/Inter-ExtraBold.ttf) format("truetype");
}
@font-face {
  font-family: "times-new-roman–bold";
  /* font-size: ; */
  font-weight: 700;
  src: url(./assets/fonts/TimesNewRoman/timesNewRomanBold.ttf) format("truetype");
}
@font-face {
  font-family: "times-new-roman-italic";
  /* font-size: ; */
  font-weight: 700;
  src: url(./assets/fonts/TimesNewRoman/timesNewRomanItalic.ttf) format("truetype");
}
@font-face {
  font-family: "times-new-roman";
  /* font-size: ; */
  font-weight: 700;
  src: url(./assets/fonts/TimesNewRoman/timesNewRoman.ttf) format("truetype");
}
@font-face {
  font-family: "times-new-roman-bold-italic";
  /* font-size: ; */
  font-weight: 700;
  src: url(./assets/fonts/TimesNewRoman/timesNewRomanBoldItalic.ttf) format("truetype");
}


.App {
  /* text-align: center; */ 
   display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  /* display: flex;
  flex-flow: row wrap; */
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
